
import AffiliateHead from './components/AffiliateHead.vue'

export default {
    name: 'Affiliate',
    components: {
        AffiliateHead,
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            userId: '',
            info: {},
            join: [],
            people: [],
            horse: {
                visible: false,
                list: []
            },
            biz: {
                hasStart: false,
                startObserver: true
            },
            page: {
                pageSize: 20
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getHorseAjax()
    },
    methods: {
        getHorseAjax() {
            this.$api.affiliate.getHorse({
                pageSize: this.page.pageSize
            }).then(response => {
                const res = response.result || {};
                this.horse.list = res || []
                this.horse.visible = !!this.horse.list.length
            })
        },
        getAffiliateInfoAjax() {
            this.$api.affiliate.getAffiliateInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {}
                this.info = res
                if(!this.info.socialMedia) {
                    this.biz.hasStart = true
                }
            })
        },
        handleStart() {
            const name = !this.hasLogin ? 'AffiliateCreate' : 'AffiliateAccount'
            const query = !this.hasLogin ? {type: 1} : {}
            this.$router.push({
                name,
                query
            })
        },
        scrollObserver(e) {
            const {isLessInnerHeight} = e
            this.biz.startObserver = !isLessInnerHeight
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.userId = this.$storage.get('userId')
            this.join = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711337721711.png',
                title: this.$translate('Commissions'),
                list: [{
                    title: this.$translate('15%+ commission')
                }, {
                    title: this.$translate('$130+ AOV,  EST earn $95 per click')
                }]
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711337762399.png',
                title: this.$translate('Products & Supports'),
                list: [{
                    title: this.$translate('5W+ products')
                }, {
                    title: this.$translate('Produce your design within 7 days')
                }, {
                    title: this.$translate('Personalized customization service is offered')
                }]
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711337769793.png',
                title: this.$translate('Friendly Payment'),
                list: [{
                    title: this.$translate('60-day duration')
                }, {
                    title: this.$translate('Pay commissions per month')
                }, {
                    title: this.$translate('Flexible payment method')
                }, {
                    title: this.$translate('Reliable affiliate partner: Sharesales')
                }]
            }]
            this.people = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711343785644.png',
                name: '@juliareno_official',
                desc: `124K ${this.$translate('followers')}`
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711507242429.png',
                name: '@nus89',
                desc: `225K ${this.$translate('followers')}`
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711343842128.png',
                name: '@lolagfdez',
                desc: `175K ${this.$translate('followers')}`
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711343845832.png',
                name: '@gabis_vintage',
                desc: `374K ${this.$translate('followers')}`
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711343848999.png',
                name: '@wissall',
                desc: `208K ${this.$translate('followers')}`
            }]
            if(this.hasLogin) {
                this.getAffiliateInfoAjax()
            } else {
                this.biz.hasStart = true
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('YFN Affiliate Program'),
                keywords: this.$translate('Promote, earn and grow with the YFN Affiliate Program. Share products you discover-get paid when customers buy directly from you'),
                description: this.$translate('Promote, earn and grow with the YFN Affiliate Program. Share products you discover-get paid when customers buy directly from you'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
