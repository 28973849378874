
export default {
    name: 'AffiliateStep',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            step: {
                title: '',
                type: [1],
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        initialSet() {
            const query = this.$route.query
            const name = this.$route.name
            const hasCreate = ['AffiliateCreate'].includes(name)
            const type = query.type || 1
            this.step.list = [{
                type: 1,
                title: this.$translate('Create Account'),
            }, {
                title: ''
            }, {
                type: 2,
                title: this.$translate('Complete Your Info'),
            }]
            this.step.title = this.$translate(type == 1 ? 'Create your YFN affiliate account' : 'Create your YFN drop shipping account')
            if(name == 'DropShippingAccount') {
                this.step.title = this.$translate('Fill in the application form')
            }
            this.step.type = hasCreate ? [1] : [1, 2]
        }
    },
}
