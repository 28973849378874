
import AffiliateHead from '../components/AffiliateHead.vue'

export default {
    name: 'AffiliateStatus',
    components: {
        AffiliateHead
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: false,
            userId: '',
            info: {},
            biz: {
                icon: '',
                title: '',
                desc: '',
                iconList: [],
                titleList: [],
                descList: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        getAffiliateInfoAjax() {
            this.$api.affiliate.getAffiliateInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {}
                this.info = res
                this.setData()
            })
        },
        getDropShippingInfoAjax() {
            this.$api.affiliate.getDropShippingInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {}
                this.info = res
                this.setData()
            })
        },
        setData() {
            const auditStatus = this.info.auditStatus || 3
            this.biz.icon = this.biz.iconList[auditStatus - 1]
            this.biz.title = this.$translate(this.biz.titleList[auditStatus - 1])
            this.biz.desc = this.$translate(this.biz.descList[auditStatus - 1])
            this.skeleton = this.info.auditStatus && true
        },
        // btn
        handleBtn(v) {
            const query = this.$route.query
            const page = query.type == 1 ? 'Affiliate' : 'DropShipping'
            const account = ['AffiliateAccount', 'DropShippingAccount'][v - 1]
            const name = ['Home', page, page, account][v - 1]
            this.$router.push({
                name
            })
        },
        // 复制
        handleCopy() {
            this.$fnApi.copy.initial('partner@yfn.com')
            this.$Toast('Copied')
        },
        // 初始化
        initialSet() {
            const query = this.$route.query
            this.userId = this.$storage.get('userId')
            this.biz.iconList = [
                'https://cdn2.selleroa.com/yfn-upload/review/1711506180299.png',
                'https://cdn2.selleroa.com/yfn-upload/review/1711506180299.png',
                'https://cdn2.selleroa.com/yfn-upload/review/1711506184875.png'
            ]
            this.biz.titleList = [
                'Waiting for Review',
                'Congrats! You are In.',
                'Rejected']
            this.biz.descList = [
                'Thank you for your application, we will review it within 24 hours. Please pay attention to your email for response!',
                'Welcome to YFN affialate! We will contact you within 3-5 business days to start our cooperation, please pay attention to your email for response!',
                'We are so sorry, due to the overwhelming response of the influencer program, we can’t accept your application at this time.']
            if(query.type == 1) {
                this.getAffiliateInfoAjax()
            } else {
                this.getDropShippingInfoAjax()
            }
        }
    },
}
