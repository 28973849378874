
import AffiliateHead from '../components/AffiliateHead.vue'
import AffiliateStep from '../components/AffiliateStep.vue'

export default {
    name: 'affiliateAccount',
    components: {
        AffiliateHead,
        AffiliateStep,
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            form: {},
            country: {
                list: []
            },
            social: {
                id: 1,
                list: [],
                options: [],
                hasAdd: true
            },
            biz: {
                hasCheck: false,
                loading: false
            },
            params: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        // this.getCountryListAjax()
    },
    methods: {
        // 获取国家
        getCountryListAjax() {
            this.$api.common.getCountryList().then(response => {
                const res = response.result || []
                this.country.list = res
                this.country.list.forEach(p => {
                    p.text = p.showName || p.englishName
                    p.value = p.countryCode
                })
            })
        },
        // 保存
        setAffiliateSaveAjax() {
            this.$api.affiliate.setAffiliateSave({
                data: JSON.stringify(this.params)
            }).then(response => {
                const res = response.result || []
                this.biz.loading = false
                if(response.code == 'success') {
                    this.$router.push({
                        name: 'AffiliateStatus',
                        query: {
                            type: 1
                        }
                    })
                }
            })
        },
        // 添加 social
        handleAdd() {
            const length = this.social.options.length
            const socialLength = this.social.list.length
            this.social.hasAdd = socialLength < length - 1
            if(socialLength == length) return
            this.social.id += 1
            this.social.list.push({
                id: this.social.id
            })
        },
        // 删除 social
        handleDelete(obj) {
            const index = this.social.list.findIndex(p => p.id == obj.id)
            this.social.list.splice(index, 1)
            this.social.hasAdd = true
        },
        // 提交
        handleSubmit() {
            this.checkForm()
            if(this.biz.hasCheck) {
                this.biz.loading = true
                this.setAffiliateSaveAjax()
            }
        },
        checkForm() {
            // 提交-校验
            let socialMediaList = [];
            this.social.list.forEach(p => {
                const platform = this.form[`platform${p.id}`] || ''
                const siteUrl = this.form[`siteUrl${p.id}`] || ''
                if(platform) {
                    socialMediaList.push({
                        platform,
                        siteUrl
                    })
                }
            })
            this.biz.hasCheck = !!this.params.gender && !!this.params.countryCode && !!socialMediaList.length
            this.params.socialMediaList = socialMediaList
        },
        // 初始化
        initialSet() {
            this.params.mallUserId = this.$storage.get('userId')
            this.country.list = this.$fnApi.mixData.affiliateCountry()
            this.social.list = [{
                id: 1
            }]
            this.social.options = [{
                text: 'Instagram',
                value: 'Instagram'
            }, {
                text: 'Facebook',
                value: 'Facebook'
            }, {
                text: 'Youtube',
                value: 'Youtube'
            }, {
                text: 'Pinterest',
                value: 'Pinterest'
            }, {
                text: 'Website',
                value: 'Website'
            }]
        }
    },
}
