
import AffiliateHead from '../components/AffiliateHead.vue'
import AffiliateStep from '../components/AffiliateStep.vue'

export default {
    name: 'DropShippingAccount',
    components: {
        AffiliateHead,
        AffiliateStep,
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            checked: [],
            form: {},
            options: {
                channel: []
            },
            country: {
                list: []
            },
            social: {
                id: 1,
                list: [],
                options: [],
                hasAdd: true
            },
            biz: {
                hasCheck: false,
                hasChannel: false,
                hasSale: false,
                hasWeb: false,
                loading: false
            },
            params: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        // this.getCountryListAjax()
    },
    methods: {
        // 获取国家
        getCountryListAjax() {
            this.$api.common.getCountryList().then(response => {
                const res = response.result || []
                this.country.list = res
                this.country.list.forEach(p => {
                    p.text = p.showName || p.englishName
                    p.value = p.countryCode
                })
            })
        },
        // 保存
        setDropShippingSave() {
            this.$api.affiliate.setDropShippingSave({
                ...this.params
            }).then(response => {
                const res = response.result || []
                this.biz.loading = false
                if(response.code == 'success') {
                    this.$router.push({
                        name: 'AffiliateStatus',
                        query: {
                            type: 2
                        }
                    })
                }
            })
        },
        // 协议
        handlePolicy(v) {
            const id = v == 1 ? '16793696198020vLSBIcB' : ''
            this.$router.push({
                name: 'Policy',
                query: {
                    id
                }
            })
        },
        // 提交
        handleSubmit() {
            this.checkForm()
            if(this.biz.hasCheck) {
                this.biz.loading = true
                this.setDropShippingSave()
            }
        },
        checkForm() {
            // 提交-校验
            const saleChannel = !!this.params.saleChannel
            const saleVolume = !!this.params.saleVolume
            const websiteLink = !!this.params.websiteLink
            if(!saleChannel) {
                this.biz.hasChannel = true
            }
            if(!saleVolume) {
                this.biz.hasSale = true
            }
            if(!websiteLink) {
                this.biz.hasWeb = true
            }
            this.biz.hasCheck = saleChannel && saleVolume && websiteLink
        },
        // 初始化
        initialSet() {
            this.params.mallUserId = this.$storage.get('userId')
            this.country.list = this.$fnApi.mixData.affiliateCountry()
            this.options.channel = [{
                text: 'Website',
                value: 1
            }, {
                text: 'Social media(Instagram,discord,etc)',
                value: 2
            }, {
                text: 'E-commerce platforms (Etsy, eBay, etc)',
                value: 3
            }, {
                text: 'Other channels',
                value: 4
            }]
            this.options.sale = [{
                text: 'Under $10000',
                value: 1
            }, {
                text: '$10000-$50000',
                value: 2
            }, {
                text: '$50000-$100000',
                value: 3
            }, {
                text: 'Over $100000',
                value: 4
            }]


            this.social.list = [{
                id: 1
            }]
            this.social.options = [{
                text: 'Instagram',
                value: 'Instagram'
            }, {
                text: 'Facebook',
                value: 'Facebook'
            }, {
                text: 'Youtube',
                value: 'Youtube'
            }, {
                text: 'Pinterest',
                value: 'Pinterest'
            }, {
                text: 'Website',
                value: 'Website'
            }]
        }
    },
}
