
import AffiliateHead from '../components/AffiliateHead.vue'

export default {
    name: 'DropShipping',
    components: {
        AffiliateHead
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            userId: '',
            tab: {
                type: 1,
                normal: [],
                tiktok: [],
                list: []
            },
            biz: {
                hasStart: false
            },
            tabCompo: {
                list: []
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        getDropShippingInfoAjax() {
            this.$api.affiliate.getDropShippingInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {}
                this.info = res
                if(!this.info.saleChannel) {
                    this.biz.hasStart = true
                }
            })
        },
        tabChange() {
            this.tab.list = this.tab.type == 1 ? this.tab.normal : this.tab.tiktok
        },
        handleStart() {
            const name = !this.hasLogin ? 'AffiliateCreate' : 'DropShippingAccount'
            const query = !this.hasLogin ? {type: 2} : {}
            this.$router.push({
                name,
                query
            })
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.userId = this.$storage.get('userId')
            this.tabCompo.list = [{
                title: this.$translate('Normal Steps'),
                name: 1
            }, {
                title: this.$translate('TikTok Creators’ Steps'),
                name: 2
            }]
            this.tab.normal = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348982303.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349015379.png',
                desc: 'Fill in the application form'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348986230.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349018778.png',
                desc: 'Create your YFN account'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348989457.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349023195.png',
                desc: 'Put YFN products in your store, and promote them'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348998340.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349026759.png',
                desc: 'Add the sold item(s) into your cart'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711349001810.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349387367.png',
                desc: 'Use your specific coupon code'
            }]
            this.tab.tiktok = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348982303.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349015379.png',
                desc: 'Fill in the application form'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348986230.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349559056.png',
                desc: 'Add YFN products to your TikTok shop'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348989457.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349023195.png',
                desc: 'Promote in your way'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1711348998340.png',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1711349563109.png',
                desc: 'Get commissions from fulfilled orders'
            }]
            this.tab.list = this.tab.normal
            if(this.hasLogin) {
                this.getDropShippingInfoAjax()
            } else {
                this.biz.hasStart = true
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('YFN Dropshipping Program'),
                keywords: this.$translate('Help creators to run your own business'),
                description: this.$translate('Help creators to run your own business'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        }
    },
}
