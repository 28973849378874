
import AffiliateHead from '../components/AffiliateHead.vue'
import AffiliateStep from '../components/AffiliateStep.vue'
import AffiliateLogin from '../affiliateLogin/index.vue'

export default {
    name: 'AffiliateCreate',
    components: {
        AffiliateHead,
        AffiliateStep,
        AffiliateLogin
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
