
import AffiliateComLogin from './components/login.vue'
import ForgotPwd from '../../login/forgotPwd/index.vue';

export default {
    name: 'Login-Page',
    components: {
        AffiliateComLogin,
        ForgotPwd
    },
    data() {
        return {
            popup: {
                visible: true,
            },
            forgotPopup:false
        }
    },
    head() {
        return this.setHead()
    },
    created() {},
    mounted() {},
    methods: {
        openPwd(){
            this.forgotPopup = true
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('login'),
                keywords: this.$translate('login'),
                description: this.$translate('login'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
